import { render, staticRenderFns } from "./NodaiOkhotskView.vue?vue&type=template&id=639996d6&scoped=true&"
import script from "./NodaiOkhotskView.vue?vue&type=script&lang=js&"
export * from "./NodaiOkhotskView.vue?vue&type=script&lang=js&"
import style0 from "./NodaiOkhotskView.vue?vue&type=style&index=0&id=639996d6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639996d6",
  null
  
)

export default component.exports